import {observer} from 'mobx-react-lite';
import React from 'react';
import * as FileIcons from 'file-icons-js';

import {useEntrances} from 'helpers';
import {AssetItem as AssetItemInfo} from 'services';

export interface AssetItemProps {
  className?: string;
  item: AssetItemInfo;
}

export const AssetItem: React.FC<AssetItemProps> = observer(
  (props: AssetItemProps) => {
    let {className, item} = props;
    let {} = useEntrances();

    let iconClassName = FileIcons.getClass(item.name) || undefined;

    return (
      <a href={item.link} target="_blank">
        <div
          className={`border-2 border-gray-200 rounded-lg px-5 py-4 mb-4 hover:border-blue-300 ${className}`}
        >
          <div className="text-lg break-all">
            {iconClassName ? (
              <i className={`${iconClassName} not-italic mr-1`}></i>
            ) : undefined}
            {item.name}
          </div>
          {item.description ? (
            <div className="text-md text-gray-400 mt-1">{item.description}</div>
          ) : undefined}
        </div>
      </a>
    );
  },
);
