import {observer} from 'mobx-react-lite';
import React, {useEffect, useState} from 'react';
import type {WatchdogVideoMeta} from '@metricsubs/youtube-watchdog';
import ReactTooltip from 'react-tooltip';

import {formatMetaDate, formatSecondsToTimeCode, useEntrances} from 'helpers';
import {getBasicInfoFromVideoMeta} from 'services';
import {R4KIcon, SubtitlesIcon} from 'components/icons';
import {Loader} from 'components/common';

const PLACEHOLDER_MIN_HEIGHT = 160;

export interface VideoCardProps {
  className?: string;
  videoMeta: WatchdogVideoMeta;
}

export const VideoCard: React.FC<VideoCardProps> = observer(
  (props: VideoCardProps) => {
    let {className, videoMeta} = props;
    let {} = useEntrances();

    const basicInfo = getBasicInfoFromVideoMeta(videoMeta);
    const [imageLoading, setImageLoading] = useState(true);
    const [imageURL, setImageURL] = useState<string | undefined>(undefined);

    useEffect(() => {
      if (basicInfo.thumbnailURL) {
        let image = new Image();
        image.src = basicInfo.thumbnailURL;
        image.onload = () => {
          setImageLoading(false);
          setImageURL(basicInfo.thumbnailURL);
        };
        image.onerror = () => {
          setImageLoading(false);
        };
      }
    });

    return (
      <div
        className={`flex flex-col rounded-xl px-5 pt-5 pb-2 border-2 border-solid border-gray-200 hover:border-blue-300 cursor-pointer ${className}`}
      >
        <Loader loading={imageLoading}>
          <div className="relative">
            <div className="absolute right-2 bottom-2 rounded-md px-2 py-0.5 bg-black bg-opacity-75 text-white text-sm ">
              {formatSecondsToTimeCode(videoMeta.duration)}
            </div>
            {imageLoading ? (
              <div
                className="bg-gray-100 w-full"
                style={{minHeight: PLACEHOLDER_MIN_HEIGHT}}
              />
            ) : imageURL ? (
              <div className="bg-gray-100 w-full">
                <img className="w-full" src={imageURL}></img>
              </div>
            ) : (
              <div>
                <div
                  className="bg-gray-100 flex flex-col justify-center items-center text-gray-400"
                  style={{minHeight: PLACEHOLDER_MIN_HEIGHT}}
                >
                  Expired
                </div>
              </div>
            )}
          </div>
        </Loader>
        <div className="mt-3">{videoMeta.title} </div>
        <div className="flex flex-row justify-between items-center mt-2 text-sm text-gray-400">
          <div>{formatMetaDate(videoMeta.uploadDate)}</div>
          <div className="flex flex-row items-center">
            {basicInfo.subtitleURL ? (
              <>
                <span data-tip data-for="subtitlesIconTip">
                  <SubtitlesIcon className="w-4" />
                </span>
                <ReactTooltip id="subtitlesIconTip" place="top" effect="solid">
                  <span>Subtitles available</span>
                </ReactTooltip>
              </>
            ) : undefined}
            {basicInfo.video4kURL ? (
              <>
                <span data-tip data-for="r4kIconTip">
                  <R4KIcon className="w-4 ml-1" />
                </span>
                <ReactTooltip id="r4kIconTip" place="top" effect="solid">
                  <span>4k available</span>
                </ReactTooltip>
              </>
            ) : undefined}
          </div>
        </div>
      </div>
    );
  },
);
