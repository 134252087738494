import {observer} from 'mobx-react-lite';
import React from 'react';

export interface ContainerProps {
  className?: string;
  children?: React.ReactNode;
}

export const Container: React.FC<ContainerProps> = observer(
  (props: ContainerProps) => {
    let {className, children} = props;

    return (
      <div className={`container mx-auto md:w-5/6 lg:w-2/3 p-5 ${className}`}>
        {children}
      </div>
    );
  },
);
