import {observer} from 'mobx-react-lite';
import React, {useEffect} from 'react';

import {useEntrances} from 'helpers';
import {Link, RouteComponentProps} from 'boring-router-react';
import {RootRouteMatch} from 'routes';
import {Header, Footer, Title, Container} from 'components/common';
import {Segment} from './@segment';
import {Card} from './@card';
import {WarningIcon} from 'components/icons';
import {action} from 'mobx';

export interface HomeViewProps extends RouteComponentProps<RootRouteMatch> {
  className?: string;
}

export const HomeView: React.FC<HomeViewProps> = observer(
  (props: HomeViewProps) => {
    let {className} = props;
    let {assetService, channelService, pageService, route} = useEntrances();

    useEffect(() => {
      const load = action(async () => {
        pageService.startLoading();

        if (!assetService.categories.length) {
          await assetService.refreshAssets();
        }

        if (!channelService.meta) {
          await channelService.refreshMeta();
        }

        pageService.stopLoading();
      });

      load().catch(console.error);
    }, []);

    return (
      <Container className={`${className}`}>
        <Title>MetricSubs / Resources</Title>
        <Header />
        <div className="mt-5 px-4 py-3 rounded-md bg-yellow-100 text-yellow-700">
          <WarningIcon className="w-4 mr-1" />
          Please note that this is for internal use only. We don't technically
          prohibit you from sharing this site, but you are advised not to do so.
        </div>
        <div className="text-3xl pl-1 pt-10  ">Have a fantastic day!</div>
        <Segment className="mt-10" title="Assets">
          <div className="flex flex-row flex-wrap">
            {assetService.categories.map(({name, description, items}) => {
              const card = <Card title={name} subtitle={description} />;

              if (!items.length) {
                return undefined;
              }

              if (
                items.length === 1 &&
                items[0].name.toLocaleLowerCase() === 'link'
              ) {
                let link = items[0].link;
                return (
                  <a key={name} href={link} target="_blank">
                    {card}
                  </a>
                );
              }

              return (
                <Link
                  key={name}
                  to={route.assets.categoryName}
                  params={{categoryName: name}}
                >
                  {card}
                </Link>
              );
            })}
          </div>
        </Segment>
        <Segment className="mt-8" title="Channel Resources">
          <div className="flex flex-row flex-wrap">
            {channelService.meta?.channels.map(({name, description, id}) => (
              <Link
                key={id}
                to={route.channels.channelId}
                params={{channelId: id}}
              >
                <Card title={name} subtitle={description} />
              </Link>
            ))}
          </div>
        </Segment>
        <Footer className="mt-20" />
      </Container>
    );
  },
);
