import {makeAutoObservable} from 'mobx';
import type {
  WatchdogChannelMeta,
  WatchdogMeta,
  WatchdogVideoMeta,
} from '@metricsubs/youtube-watchdog';

const CHANNEL_JSON_URL = useGitLabProxy
  ? 'https://us.vlan.tinyws.com/metricsubs-org-gitlab-io/youtube-watchdog/watchdog-meta.json'
  : 'https://metricsubs-org.gitlab.io/youtube-watchdog/watchdog-meta.json';

export class ChannelService {
  meta: WatchdogMeta | undefined = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  async refreshMeta() {
    let response = await fetch(CHANNEL_JSON_URL);
    this.meta = await response.json();
  }

  getChannelMeta(channelId: string): WatchdogChannelMeta | undefined {
    let meta = this.meta;

    if (!meta) {
      return;
    }

    return meta.channels.find(channel => channel.id === channelId);
  }
}

export interface VideoBasicInfo {
  id: string;
  updatedAt: number;
  thumbnailURL?: string;
  video1080pURL: string;
  video4kURL?: string;
  subtitleURL?: string;
}

export function getBasicInfoFromVideoMeta(
  videoMeta: WatchdogVideoMeta,
): VideoBasicInfo {
  let {id, updatedAt, downloads} = videoMeta;

  let thumbnailURL = downloads.find(download => download.type === 'thumbnail')
    ?.url;
  let subtitleURL = downloads.find(download => download.type === 'subtitles')
    ?.url;
  let video1080pURL = downloads.find(
    download => download.type === 'video-1080p',
  )!.url;
  let video4kURL = downloads.find(download => download.type === 'video-4k')
    ?.url;

  return {
    id,
    updatedAt,
    thumbnailURL,
    video1080pURL,
    subtitleURL,
    video4kURL,
  };
}
