import {observer} from 'mobx-react-lite';
import React, {useEffect} from 'react';

import LogoSVGString from 'assets/inline/logo.svg';

import './@loader.css';

export interface PageLoaderProps {
  className?: string;
  loading: boolean;
}

export const PageLoader: React.FC<PageLoaderProps> = observer(
  (props: PageLoaderProps) => {
    let {className, loading} = props;

    useEffect(() => {
      let bodyElement = document.querySelector('body');

      if (!bodyElement) {
        return;
      }

      if (loading) {
        bodyElement.style.height = '100%';
        bodyElement.style.overflow = 'hidden';
      } else {
        bodyElement.style.height = 'auto';
        bodyElement.style.overflow = 'auto';
      }
    });

    return (
      <div
        className={`fixed left-0 right-0 top-0 bottom-0 flex justify-center items-center ${className} bg-white transition-opacity`}
        style={{
          opacity: loading ? 1 : 0,
          pointerEvents: loading ? 'all' : 'none',
        }}
      >
        <div
          style={{
            width: 40,
            height: 40,
            backgroundColor: '#fff',
            animation: 'sk-rotateplane 1.2s infinite ease-in-out',
          }}
          dangerouslySetInnerHTML={{__html: LogoSVGString}}
        ></div>
      </div>
    );
  },
);
