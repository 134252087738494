import {observer} from 'mobx-react-lite';
import React, {useEffect} from 'react';

import {useEntrances} from 'helpers';
import {PieChartIcon} from 'components/icons';

export interface ProxyDataUsageProps {
  className?: string;
}

export const ProxyDataUsage: React.FC<ProxyDataUsageProps> = observer(
  (props: ProxyDataUsageProps) => {
    let {className} = props;
    let {serverService} = useEntrances();
    let status = serverService.status;

    let usageRatio = 0;
    let barColor = 'rgb(52, 211, 153)';

    if (status) {
      usageRatio = status.dataUsage / status.monthlyTotalData;

      if (usageRatio > 0.9) {
        barColor = 'rgb(248, 113, 113)';
      } else if (usageRatio > 0.6) {
        barColor = 'rgb(251, 191, 36)';
      }
    }

    useEffect(() => {
      serverService.fetchServerService().catch(console.error);
    }, []);

    return (
      <div
        className={`border-2 border-yellow-100 text-yellow-800 rounded-lg py-2 px-3 ${className}`}
      >
        {status ? (
          <>
            <div className="flex flex-row justify-between items-center">
              <div>
                <PieChartIcon className="w-4 mr-2 inline-block" />
                Proxy data usage:
              </div>
              <div className="text-sm text-gray-400">
                Next reset on: {processUnixTimestamp(status.nextResetAt)}
              </div>
            </div>
            <div className="mt-3 h-2 bg-gray-50 rounded-md w-full">
              <div
                className="h-2 rounded-md"
                style={{
                  width: `${(usageRatio * 100).toFixed(4)}%`,
                  backgroundColor: barColor,
                }}
              ></div>
            </div>
            <div className="mt-2 flex flex-row justify-between items-center text-sm text-gray-500">
              <div>{(usageRatio * 100).toFixed(0)}%</div>
              <div>
                {processBytesIntoGB(status.dataUsage).toFixed(1)}/
                {processBytesIntoGB(status.monthlyTotalData).toFixed(0)}GB
              </div>
            </div>
          </>
        ) : (
          <div>Loading...</div>
        )}
      </div>
    );
  },
);

function processUnixTimestamp(timestamp?: number): string {
  if (!timestamp) {
    return 'Unknown';
  }

  let date = new Date(timestamp * 1000);

  return date.toLocaleDateString();
}

function processBytesIntoGB(bytesCount: number): number {
  return bytesCount / 1024 / 1024 / 1024;
}
