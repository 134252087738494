import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import {useEntrances} from 'helpers';
import {Link, Route, RouteComponentProps} from 'boring-router-react';
import {RootRouteMatch} from 'routes';
import {VideoView} from './@video/video-view';
import {Container, Footer, Header, SubHeader, Title} from 'components/common';
import {VideoCard} from './@video-card';

export interface ChannelViewProps
  extends RouteComponentProps<RootRouteMatch['channels']['channelId']> {
  className?: string;
}

export const ChannelView: React.FC<ChannelViewProps> = observer(
  (props: ChannelViewProps) => {
    let {className, match} = props;
    let {history, route} = useEntrances();

    const channelMeta = match.channelMeta;

    const onSubHeaderBackButtonClick = useCallback(() => {
      history.replace(route.$ref());
    }, []);

    return (
      <div className={`${className}`}>
        <Route match={match.videoId} component={VideoView}></Route>
        <Route match={match} exact>
          <Container>
            <Title defaultAffix>{channelMeta.name}</Title>
            <Header />
            <SubHeader
              className="py-5"
              onBackButtonClick={onSubHeaderBackButtonClick}
            >
              {channelMeta.name}
            </SubHeader>
            {channelMeta.description ? (
              <div className="text-lg text-gray-400 pl-1">
                {channelMeta.description}
              </div>
            ) : undefined}
            <div className="mt-5 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
              {channelMeta.videos.map(videoMeta => (
                <Link
                  key={videoMeta.id}
                  to={match.videoId}
                  params={{
                    videoId: videoMeta.id,
                    channelId: match.$params.channelId,
                  }}
                >
                  <VideoCard videoMeta={videoMeta} />
                </Link>
              ))}
            </div>
            <Footer className="mt-8" />
          </Container>
        </Route>
      </div>
    );
  },
);
