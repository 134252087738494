import {observer} from 'mobx-react-lite';
import React from 'react';

export interface SubtitlesIconProps {
  className?: string;
}

export const SubtitlesIcon: React.FC<SubtitlesIconProps> = observer(
  (props: SubtitlesIconProps) => {
    let {className} = props;

    return (
      <svg
        className={`${className}`}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
        />
      </svg>
    );
  },
);
