import {makeAutoObservable} from 'mobx';

const SERVER_STATUS_API_ENDPOINT =
  'https://demo.dizy.cc/metricsubs/server/status.php';

export interface ServerStatus {
  monthlyTotalData: number;
  dataUsage: number;
  nextResetAt: number;
}

export interface ResponseData {
  code: number;
  message: string;
  data: ServerStatus;
}

export class ServerService {
  status: ServerStatus | undefined = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  async fetchServerService() {
    let response = await fetch(SERVER_STATUS_API_ENDPOINT);

    let responseData: ResponseData = await response.json();

    if (!('data' in responseData)) {
      throw new Error('Failed to fetch server status');
    }

    this.status = responseData.data;
  }
}
