import {action, makeAutoObservable} from 'mobx';

export class PageService {
  loading = true;

  constructor() {
    makeAutoObservable(this);
  }

  @action
  startLoading() {
    this.loading = true;
  }

  @action
  stopLoading() {
    this.loading = false;
  }
}
