import {observer} from 'mobx-react-lite';
import React from 'react';

export interface InfoIconProps {
  className?: string;
}

export const InfoIcon: React.FC<InfoIconProps> = observer(
  (props: InfoIconProps) => {
    let {className} = props;

    return (
      <svg
        className={`${className}`}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>
    );
  },
);
