export const DIRECT_SITE_URL = 'https://res-direct.metricsubs.org';

export function formatMetaDate(date: string): string {
  date = String(date);

  if (date.length !== 8) {
    return date;
  }

  let year = date.slice(0, 4);
  let month = date.slice(4, 6);
  let day = date.slice(6, 8);

  return `${year}-${month}-${day}`;
}

export function formatSecondsToTimeCode(seconds: number): string {
  let minutes = Math.floor(seconds / 60);
  seconds = seconds % 60;
  let secondsStr = String(seconds);
  if (seconds < 10) {
    secondsStr = `0${secondsStr}`;
  }

  return `${minutes}:${secondsStr}`;
}
