import {observer} from 'mobx-react-lite';
import React from 'react';

import {useEntrances} from 'helpers';
import {RouteComponentProps} from 'boring-router-react';
import {RootRouteMatch} from 'routes';

export interface NotFoundViewProps
  extends RouteComponentProps<RootRouteMatch['notFound']> {
  className?: string;
}

export const NotFoundView: React.FC<NotFoundViewProps> = observer(
  (props: NotFoundViewProps) => {
    let {className} = props;
    let {} = useEntrances();

    return <div className={`${className}`}>not-found-view</div>;
  },
);
