export function svgToDataURL(svgStr: string): string {
  let encoded = encodeURIComponent(svgStr)
    .replace(/'/g, '%27')
    .replace(/"/g, '%22');

  let header = 'data:image/svg+xml,';
  let dataUrl = header + encoded;

  return dataUrl;
}
