import {observer} from 'mobx-react-lite';
import React from 'react';

import {svgToDataURL, useEntrances} from 'helpers';

import LogoSVGRaw from 'assets/inline/logo.svg';

export interface HeaderProps {
  className?: string;
}

export const Header: React.FC<HeaderProps> = observer((props: HeaderProps) => {
  let {className} = props;
  let {} = useEntrances();

  return (
    <div
      className={`container flex flex-row justify-between items-center ${className}`}
    >
      <div className="container flex flex-row flex-wrap items-center">
        <img
          className="w-8 mr-4 mt-1 mb-1"
          src={svgToDataURL(LogoSVGRaw)}
        ></img>
        <div className="mr-2 mt-1 mb-1  text-gray-600">MetricSubs</div>
        <div className="mt-1 mb-1 text-gray-600 text-opacity-50 flex-shrink-0">
          /&nbsp;&nbsp;Resources
        </div>
      </div>
    </div>
  );
});
