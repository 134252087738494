import {observer} from 'mobx-react-lite';
import React, {useEffect} from 'react';

import {useEntrances} from 'helpers';
import {RouteComponentProps} from 'boring-router-react';
import {RootRouteMatch} from 'routes';
import {Container, Footer, Header, SubHeader, Title} from 'components/common';
import {AssetItem} from './@asset-item';

export interface AssetViewProps
  extends RouteComponentProps<RootRouteMatch['assets']['categoryName']> {
  className?: string;
}

export const AssetView: React.FC<AssetViewProps> = observer(
  (props: AssetViewProps) => {
    let {className, match} = props;
    let {history, route} = useEntrances();

    const category = match.category;

    const onSubHeaderBackButtonClick = () => {
      history.replace(route.$ref());
    };

    return (
      <Container className={`${className}`}>
        <Title defaultAffix>{category.name}</Title>
        <Header />
        <SubHeader
          className="py-5"
          onBackButtonClick={onSubHeaderBackButtonClick}
        >
          {category.name}
        </SubHeader>
        {category.description ? (
          <div className="text-lg text-gray-400 pl-1">
            {category.description}
          </div>
        ) : undefined}
        <div className="mt-5">
          {category.items.map(item => (
            <AssetItem key={item.name} item={item} />
          ))}
        </div>
        <Footer className="mt-8" />
      </Container>
    );
  },
);
