import {observer} from 'mobx-react-lite';
import React from 'react';

import './@loader.css';

export interface LoaderProps {
  className?: string;
  maskClassName?: string;
  spinnerClassName?: string;
  loading: boolean;
  children: React.ReactNode;
}

export const Loader: React.FC<LoaderProps> = observer((props: LoaderProps) => {
  let {className, maskClassName, spinnerClassName, loading, children} = props;

  return (
    <div className={`relative ${className}`}>
      <div
        className={`absolute left-0 top-0 bottom-0 right-0 z-20 bg-white transition-opacity ${maskClassName}`}
        style={{
          opacity: loading ? 0.4 : 0,
          pointerEvents: loading ? 'all' : 'none',
          transitionDuration: '0.2s',
        }}
      ></div>
      <div
        className="absolute left-0 top-0 bottom-0 right-0 z-30 flex flex-col justify-center items-center"
        style={{display: loading ? 'flex' : 'none'}}
      >
        <div className={`sk-circle w-8 h-8 ${spinnerClassName}`}>
          <div className="sk-circle1 sk-child"></div>
          <div className="sk-circle2 sk-child"></div>
          <div className="sk-circle3 sk-child"></div>
          <div className="sk-circle4 sk-child"></div>
          <div className="sk-circle5 sk-child"></div>
          <div className="sk-circle6 sk-child"></div>
          <div className="sk-circle7 sk-child"></div>
          <div className="sk-circle8 sk-child"></div>
          <div className="sk-circle9 sk-child"></div>
          <div className="sk-circle10 sk-child"></div>
          <div className="sk-circle11 sk-child"></div>
          <div className="sk-circle12 sk-child"></div>
        </div>
      </div>
      {children}
    </div>
  );
});
