import {observer} from 'mobx-react-lite';
import React from 'react';

export interface ChevronLeftIconProps {
  className?: string;
}

export const ChevronLeftIcon: React.FC<ChevronLeftIconProps> = observer(
  (props: ChevronLeftIconProps) => {
    let {className} = props;

    return (
      <svg
        className={`inline-block ${className}`}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M15 19l-7-7 7-7"
        />
      </svg>
    );
  },
);
