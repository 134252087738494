import {observer} from 'mobx-react-lite';
import React from 'react';

import {useEntrances} from 'helpers';
import {ChevronLeftIcon} from 'components/icons';

export interface SubHeaderProps {
  className?: string;
  children?: React.ReactNode;
  onBackButtonClick?: React.MouseEventHandler;
}

export const SubHeader: React.FC<SubHeaderProps> = observer(
  (props: SubHeaderProps) => {
    let {className, children, onBackButtonClick} = props;
    let {history} = useEntrances();

    const defaultOnBackButtonClick = () => {
      history.back().catch(console.error);
    };

    return (
      <div className={`flex flex-row items-center ${className}`}>
        <div
          className="w-8 h-8 flex items-center justify-center rounded-md cursor-pointer hover:bg-gray-50"
          onClick={onBackButtonClick ?? defaultOnBackButtonClick}
        >
          <ChevronLeftIcon className="w-5 text-gray-400" />
        </div>
        <div className="text-2xl ml-2">{children}</div>
      </div>
    );
  },
);
