import {observer} from 'mobx-react-lite';
import React from 'react';

import {useEntrances} from 'helpers';

export interface CardProps {
  className?: string;
  title: string | React.ReactNode;
  subtitle?: string | React.ReactNode;
}

export const Card: React.FC<CardProps> = observer((props: CardProps) => {
  let {className, title, subtitle} = props;
  let {} = useEntrances();

  return (
    <div
      className={`mt-4 rounded-xl p-5 border-2 border-solid border-gray-200 hover:border-blue-300 cursor-pointer mr-5 max-w-xs ${className}`}
    >
      <div className="text-lg">{title}</div>
      {subtitle ? (
        <div className="text-sm text-gray-400 mt-2">{subtitle}</div>
      ) : undefined}
    </div>
  );
});
