import {makeAutoObservable} from 'mobx';

const ASSET_WIKI_API = useGitLabProxy
  ? 'https://us.vlan.tinyws.com/gitlab/api/v4/projects/metricsubs-org%2Fassets/wikis/Assets'
  : 'https://gitlab.com/api/v4/projects/metricsubs-org%2Fassets/wikis/Assets';
const ASSET_WIKI_URL = 'https://gitlab.com/metricsubs-org/assets/-/wikis/';

export interface AssetItem {
  name: string;
  description?: string;
  link: string;
}

export interface AssetCategory {
  name: string;
  description?: string;
  items: AssetItem[];
}

interface WikiJSON {
  format: 'markdown';
  slug: string;
  title: string;
  content: string;
}

export class AssetService {
  categories: AssetCategory[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  async refreshAssets() {
    let response = await fetch(ASSET_WIKI_API);

    let {content}: WikiJSON = await response.json();

    this.categories = processWikiJSONContent(content);
  }

  getAssetCategory(categoryName: string): AssetCategory | undefined {
    return this.categories.find(category => category.name === categoryName);
  }
}

function processWikiJSONContent(content: string): AssetCategory[] {
  let lines = content.split('\n');
  let result: AssetCategory[] = [];

  let thisCategory: AssetCategory | undefined = undefined;

  for (let line of lines) {
    line = line.trim();
    if (line.startsWith('## ')) {
      if (thisCategory) {
        result.push(thisCategory);
      }

      let name = line.slice(3).trim();
      thisCategory = {name, items: []};
    } else if (thisCategory) {
      if (line.startsWith('- ')) {
        let matches = line.slice(2).match(/^(.*?)\[(.*?)\]\((.*?)\)$/);
        if (matches) {
          let description = matches[1].trim() || undefined;
          if (description && description.endsWith(':')) {
            description = description.slice(0, -1).trim();
          }
          let name = matches[2].trim();
          let link = matches[3].trim();
          if (link.startsWith('uploads/')) {
            link = `${ASSET_WIKI_URL}${link}`;
          }
          thisCategory.items.push({
            name,
            description,
            link,
          });
        }
      } else if (line.startsWith('> ') || line.startsWith('| ')) {
        undefined;
      } else if (line) {
        if (thisCategory.description) {
          thisCategory.description += line;
        } else {
          thisCategory.description = line;
        }
      }
    }
  }

  if (thisCategory) {
    result.push(thisCategory);
  }

  return result;
}
