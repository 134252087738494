import {observer} from 'mobx-react-lite';
import React, {useEffect} from 'react';

import {useEntrances} from 'helpers';

export interface TitleProps {
  children: string | string[];
  defaultAffix?: boolean;
}

export const Title: React.FC<TitleProps> = observer((props: TitleProps) => {
  let {children, defaultAffix} = props;

  useEffect(() => {
    let originalTitle = window.document.title;

    let titleArray = typeof children === 'string' ? [children] : children;
    let title = titleArray.join('');

    window.document.title = defaultAffix
      ? `${title} - MetricSubs / Resources`
      : title;

    return () => {
      window.document.title = originalTitle;
    };
  }, []);

  return <></>;
});
