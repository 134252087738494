import {
  WatchdogChannelMeta,
  WatchdogVideoMeta,
} from '@metricsubs/youtube-watchdog';
import {RootRouteMatchType, RouteMatch, schema} from 'boring-router';
import {AssetCategory} from 'services';

export const routeSchema = schema({
  $exact: true,
  $children: {
    assets: {
      $children: {
        categoryName: {
          $exact: true,
          $match: RouteMatch.SEGMENT,
          $extension: {
            category: undefined! as AssetCategory,
          },
        },
      },
    },
    channels: {
      $children: {
        channelId: {
          $exact: true,
          $match: RouteMatch.SEGMENT,
          $extension: {
            channelMeta: undefined! as WatchdogChannelMeta,
          },
          $children: {
            videoId: {
              $exact: true,
              $match: RouteMatch.SEGMENT,
            },
          },
        },
      },
    },
    notFound: {
      $match: /.*/,
    },
  },
});

export type RootRouteMatch = RootRouteMatchType<
  typeof routeSchema,
  undefined,
  string
>;
