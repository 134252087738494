import 'regenerator-runtime/runtime';

import React from 'react';
import ReactDom from 'react-dom';
import {
  transitions,
  positions,
  Provider as AlertProvider,
  AlertProviderProps,
} from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';

import {App} from 'views/app';

import {Entrances} from './@entrances';
import {EntrancesContext} from './helpers';

import './style.css';

const ALERT_OPTIONS: AlertProviderProps = {
  template: AlertTemplate,
  position: positions.BOTTOM_CENTER,
  timeout: 5000,
  offset: '30px',
  transition: transitions.FADE,
};

async function main(): Promise<void> {
  let entrances = new Entrances();

  await entrances.promise;

  entrances.pageService.stopLoading();

  ReactDom.render(
    <EntrancesContext.Provider value={entrances}>
      <AlertProvider {...ALERT_OPTIONS}>
        <App />
      </AlertProvider>
    </EntrancesContext.Provider>,
    document.getElementById('app'),
  );
}

main().catch(console.error);
