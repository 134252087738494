/* eslint-disable @mufan/explicit-return-type */
import {Router} from 'boring-router';
import {BrowserHistory} from 'boring-router-react';
import entrance from 'entrance-decorator';

import {RootRouteMatch, routeSchema} from 'routes';
import {
  AssetRouteService,
  AssetService,
  ChannelRouteService,
  ChannelService,
  PageService,
  ServerService,
} from 'services';

export class Entrances {
  constructor() {
    this.initializeRouteServices();
  }

  @entrance
  get history() {
    return new BrowserHistory({hash: false});
  }

  @entrance
  get router() {
    return new Router(this.history, {});
  }

  @entrance
  get route(): RootRouteMatch {
    return this.router.$route(routeSchema);
  }

  @entrance
  get serverService() {
    return new ServerService();
  }

  @entrance
  get pageService() {
    return new PageService();
  }

  @entrance
  get assetService() {
    return new AssetService();
  }

  @entrance
  get channelService() {
    return new ChannelService();
  }

  initializeRouteServices() {
    this.history.listen(() => {
      window.scrollTo(0, 0);
    });

    this.route.assets.categoryName.$service(
      () =>
        new AssetRouteService(
          this.history,
          this.pageService,
          this.assetService,
        ),
    );

    this.route.channels.channelId.$service(
      () =>
        new ChannelRouteService(
          this.history,
          this.pageService,
          this.channelService,
        ),
    );
  }

  promise = Promise.all([]);
}
