import {observer} from 'mobx-react-lite';
import React from 'react';

export interface R4KIconProps {
  className?: string;
}

export const R4KIcon: React.FC<R4KIconProps> = observer(
  (props: R4KIconProps) => {
    let {className} = props;

    return (
      <svg
        className={`${className}`}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1024 1024"
        fill="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M170.666667 213.333333v597.333334h682.666666V213.333333H170.666667zM128 128h768a42.666667 42.666667 0 0 1 42.666667 42.666667v682.666666a42.666667 42.666667 0 0 1-42.666667 42.666667H128a42.666667 42.666667 0 0 1-42.666667-42.666667V170.666667a42.666667 42.666667 0 0 1 42.666667-42.666667z m362.666667 448h-42.666667V640H384v-64H256V384h64v128H384V384h64v128h42.666667v64zM768 640h-74.666667l-74.666666-96V640H554.666667V384h64v96L693.333333 384H768l-96 128L768 640z"
        ></path>
      </svg>
    );
  },
);
