import {observer} from 'mobx-react-lite';
import React, {useCallback, useEffect, useState} from 'react';
import DPlayer from 'react-dplayer';

import {DIRECT_SITE_URL, formatMetaDate, useEntrances} from 'helpers';
import {RootRouteMatch} from 'routes';
import {RouteComponentProps} from 'boring-router-react';
import {Container, Footer, Header, SubHeader, Title} from 'components/common';
import {
  ClipboardIcon,
  InfoIcon,
  LighteningIcon,
  VideoIcon,
} from 'components/icons';
import {getBasicInfoFromVideoMeta} from 'services';
import clipboardCopy from 'clipboard-copy';
import {useAlert} from 'react-alert';
import ReactTooltip from 'react-tooltip';
import {DownloadCard} from './@download-card';
import {ProxyDataUsage} from './@proxy-data-usage';

export interface VideoViewProps
  extends RouteComponentProps<
    RootRouteMatch['channels']['channelId']['videoId']
  > {
  className?: string;
}

export const VideoView: React.FC<VideoViewProps> = observer(
  (props: VideoViewProps) => {
    let {className, match} = props;
    let {route, history} = useEntrances();
    let proxyOn = useGitLabProxy;
    const alert = useAlert();

    const videoId = decodeURIComponent(match.$params.videoId);
    const channelMeta = route.channels.channelId.channelMeta;
    const videoMeta = channelMeta.videos.find(
      videoMeta => videoMeta.id === videoId,
    );

    if (!videoMeta) {
      history.replace('/404');
      return <></>;
    }

    const basicVideoInfo = getBasicInfoFromVideoMeta(videoMeta);

    const onSubHeaderBackButtonClick = useCallback(() => {
      history.replace(
        route.channels.channelId.$ref({channelId: channelMeta.id}),
      );
    }, []);

    const onTitleCopyButtonClick = useCallback(() => {
      clipboardCopy(videoMeta.title)
        .then(() => {
          alert.success('Title copied!');
        })
        .catch(() => {
          alert.error('Failed to copy!');
        });
    }, []);

    const onOriginLinkCopyButtonClick = useCallback(() => {
      clipboardCopy(getOriginalYouTubeLink(videoMeta.id))
        .then(() => {
          alert.success('Origin link copied!');
        })
        .catch(() => {
          alert.error('Failed to copy!');
        });
    }, []);

    const onDescriptionCopyButtonClick = useCallback(() => {
      clipboardCopy(getDescriptionParts(videoMeta.description)[0])
        .then(() => {
          alert.success('Description copied!');
        })
        .catch(() => {
          alert.error('Failed to copy!');
        });
    }, []);

    return (
      <Container className={`${className}`}>
        <ReactTooltip id="copyHint" place="top" effect="solid">
          <span>Copy</span>
        </ReactTooltip>
        <Title defaultAffix>
          {videoMeta.title} - {channelMeta.name}
        </Title>
        <Header />
        <SubHeader
          className="py-5"
          onBackButtonClick={onSubHeaderBackButtonClick}
        >
          <div className="flex flex-row items-center">
            <div className="inline-block rounded-md bg-green-100 text-green-500 px-2 py-1 mr-3">
              <VideoIcon className="w-6" />
            </div>
            {videoMeta.title}
            <div
              className="ml-2 w-8 h-8 p-2 bg-white shadow-md rounded-md text-gray-500 cursor-pointer hover:shadow-lg hover:text-gray-600 transition-all"
              onClick={onTitleCopyButtonClick}
              data-tip
              data-for="copyHint"
            >
              <ClipboardIcon />
            </div>
          </div>
        </SubHeader>
        <div className="">
          <DPlayer
            options={{
              preload: 'none',
              video: {
                url: basicVideoInfo.video1080pURL,
                pic: basicVideoInfo.thumbnailURL,
                quality: [
                  {
                    name: '1080p',
                    url: basicVideoInfo.video1080pURL,
                  },
                  ...(basicVideoInfo.video4kURL
                    ? [{name: '4k', url: basicVideoInfo.video4kURL}]
                    : []),
                ],
                defaultQuality: 0,
              },
            }}
          />
        </div>
        <div className="mt-5 text-gray-400">
          Uploaded by{' '}
          <a
            className="text-blue-500"
            href={`https://www.youtube.com/channel/${channelMeta.id}`}
          >
            {channelMeta.name}
          </a>{' '}
          on {formatMetaDate(videoMeta.uploadDate)}
        </div>
        <div className="mt-4 relative">
          <div className="">Origin link</div>
          <div className="mt-2 relative">
            <div
              className="absolute top-2 right-7 w-8 p-2 bg-white shadow-md rounded-md text-gray-500 cursor-pointer hover:shadow-lg hover:text-gray-600 transition-all"
              onClick={onOriginLinkCopyButtonClick}
              data-tip
              data-for="copyHint"
            >
              <ClipboardIcon />
            </div>
            <div className="border-solid border-2 p-3 rounded-md text-gray-500 bg-gray-50 whitespace-pre-wrap max-h-80 overflow-y-auto">
              {getOriginalYouTubeLink(videoMeta.id)}
            </div>
          </div>
        </div>
        <div className="mt-6 relative">
          <div className="">Description</div>
          <div className="mt-2 relative">
            <div
              className="absolute top-3 right-7 w-8 h-8 p-2 bg-white shadow-md rounded-md text-gray-500 cursor-pointer hover:shadow-lg hover:text-gray-600 transition-all"
              onClick={onDescriptionCopyButtonClick}
              data-tip
              data-for="copyHint"
            >
              <ClipboardIcon />
            </div>
            <div className="border-solid border-2 p-3 rounded-md text-gray-500 bg-gray-50 whitespace-pre-wrap max-h-80 overflow-y-auto">
              {getDescriptionParts(videoMeta.description)[0]}
            </div>
          </div>
        </div>
        <div className="mt-6">
          <div className="flex flex-row items-center">
            <div>Downloads ({videoMeta.downloads.length} files)</div>
            <div className="ml-3">
              {proxyOn ? (
                <div className=" text-gray-400">
                  Use{' '}
                  <a
                    className="text-green-500"
                    href={DIRECT_SITE_URL + history.url}
                  >
                    <LighteningIcon className="w-4 inline-block mr-1" />
                    the direct site
                  </a>{' '}
                  instead to help us save bandwidth, if a VPN is available to
                  you.
                  <div
                    className="bg-yellow-100 ml-2 w-6 h-6 inline-flex justify-center items-center rounded-md text-yellow-500 cursor-pointer"
                    data-tip
                    data-for="proxiedSiteHint"
                  >
                    <InfoIcon className="w-4" />
                  </div>
                  <ReactTooltip id="proxiedSiteHint" place="top" effect="solid">
                    <span>
                      The downloads on this page are proxied by a personal
                      server, which <br></br>
                      has limited bandwidth quota. Please use the direct
                      <br></br> site whenever you can.
                    </span>
                  </ReactTooltip>
                </div>
              ) : (
                <div className="text-green-400">
                  <LighteningIcon className="w-4 inline-block mr-1" />
                  You're using the direct site. And that's fantastic!
                </div>
              )}
            </div>
          </div>
          {proxyOn ? <ProxyDataUsage className="mt-3" /> : undefined}
          <div className="mt-3 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
            {videoMeta.downloads
              .slice()
              .sort((a, b) => a.type.localeCompare(b.type))
              .map(download => (
                <DownloadCard key={download.url} download={download} />
              ))}
          </div>
        </div>
        <Footer className="mt-8" />
      </Container>
    );
  },
);

function getDescriptionParts(description: string): string[] {
  let strArr = description.split('\nNEWS SOURCES');

  if (strArr.length <= 1) {
    return [description];
  }

  let mainPart = strArr[0].trim();
  let restPart = strArr.slice(1).join('\nNEWS SOURCES').trim();

  return [mainPart, restPart];
}

function getOriginalYouTubeLink(videoId: string): string {
  return `https://www.youtube.com/watch?v=${videoId}`;
}
