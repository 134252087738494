import {Route} from 'boring-router-react';
import {observer} from 'mobx-react-lite';
import React from 'react';

import {useEntrances} from 'helpers';
import {HomeView} from './home';
import {AssetView} from './asset';
import {ChannelView} from './channel';
import {NotFoundView} from './not-found';
import {PageLoader} from 'components/common';

export const App: React.FC = observer(() => {
  let {route, pageService} = useEntrances();

  return (
    <div className="text-gray-600">
      <PageLoader loading={pageService.loading} />
      <Route match={route} exact component={HomeView} />
      <Route match={route.assets.categoryName} component={AssetView} />
      <Route match={route.channels.channelId} component={ChannelView} />
      <Route match={route.notFound} component={NotFoundView} />
    </div>
  );
});
