import {IRouteService} from 'boring-router';
import {BrowserHistory} from 'boring-router-react';
import {makeAutoObservable} from 'mobx';
import {RootRouteMatch} from 'routes';

import {PageService} from '../page-service';
import {AssetCategory, AssetService} from '../asset-service';

type AssetCategoryNameRoute = RootRouteMatch['assets']['categoryName'];

export class AssetRouteService
  implements IRouteService<AssetCategoryNameRoute> {
  category: AssetCategory = undefined!;

  constructor(
    private history: BrowserHistory,
    private pageService: PageService,
    private assetService: AssetService,
  ) {
    makeAutoObservable(this);
  }

  async willEnter(next: AssetCategoryNameRoute['$next']): Promise<void> {
    let assetService = this.assetService;

    if (!assetService.categories.length) {
      this.pageService.startLoading();
      await assetService.refreshAssets();
      this.pageService.stopLoading();
    }

    let categoryName = decodeURIComponent(next.$params.categoryName);

    let category = assetService.getAssetCategory(categoryName);

    if (!category) {
      this.history.replace('/404');
      return;
    }

    this.category = category;
  }

  afterLeave(): void {
    this.category = undefined!;
  }
}
