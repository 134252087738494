import {observer} from 'mobx-react-lite';
import React from 'react';

import {useEntrances} from 'helpers';

export interface SegmentProps {
  className?: string;
  children?: React.ReactNode;
  title: string | React.ReactNode;
}

export const Segment: React.FC<SegmentProps> = observer(
  (props: SegmentProps) => {
    let {className, children, title} = props;
    let {} = useEntrances();

    return (
      <div className={`${className}`}>
        <div className="pl-2 text-xl">{title}</div>
        <div className="">{children}</div>
      </div>
    );
  },
);
