import {IRouteService} from 'boring-router';
import {BrowserHistory} from 'boring-router-react';
import {makeAutoObservable} from 'mobx';
import {RootRouteMatch} from 'routes';

import {PageService} from '../page-service';
import {ChannelService} from '../channel-service';
import {WatchdogChannelMeta} from '@metricsubs/youtube-watchdog';

type ChannelIdRoute = RootRouteMatch['channels']['channelId'];

export class ChannelRouteService implements IRouteService<ChannelIdRoute> {
  channelMeta!: WatchdogChannelMeta;

  constructor(
    private history: BrowserHistory,
    private pageService: PageService,
    private channelService: ChannelService,
  ) {
    makeAutoObservable(this);
  }

  async willEnter(next: ChannelIdRoute['$next']): Promise<void> {
    let channelService = this.channelService;

    if (!channelService.meta) {
      this.pageService.startLoading();
      await channelService.refreshMeta();
      this.pageService.stopLoading();
    }

    let channelId = decodeURIComponent(next.$params.channelId);

    let channelMeta = channelService.getChannelMeta(channelId);

    if (!channelMeta) {
      this.history.replace('/404');
      return;
    }

    this.channelMeta = channelMeta;
  }

  afterLeave(): void {
    this.channelMeta = undefined!;
  }
}
