import {observer} from 'mobx-react-lite';
import React from 'react';

import {useEntrances} from 'helpers';

export interface FooterProps {
  className?: string;
}

export const Footer: React.FC<FooterProps> = observer((props: FooterProps) => {
  let {className} = props;
  let {} = useEntrances();

  return (
    <div className={`text-gray-400 ${className}`}>
      © {new Date().getFullYear()} MetricSubs
    </div>
  );
});
