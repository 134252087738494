import {observer} from 'mobx-react-lite';
import React, {ReactNode} from 'react';
import * as FileIcons from 'file-icons-js';

import {useEntrances} from 'helpers';
import {WatchdogVideoDownloadFileInfo} from '@metricsubs/youtube-watchdog';
import {InfoIcon, R1080PIcon, R4KIcon, SubtitlesIcon} from 'components/icons';
import {ImageIcon} from 'components/icons/image-icon';

const PREFIX_WRAPPER_CLASS_NAME =
  'w-9 h-6 flex justify-center items-center mr-3 rounded-md';

export interface DownloadCardProps {
  className?: string;
  download: WatchdogVideoDownloadFileInfo;
}

export const DownloadCard: React.FC<DownloadCardProps> = observer(
  (props: DownloadCardProps) => {
    let {className, download} = props;
    let {} = useEntrances();

    let basename = getBasenameFromURL(download.url);

    let iconClassName = FileIcons.getClass(basename) || undefined;

    let prefixElement: ReactNode;

    if (download.type === 'video-4k') {
      prefixElement = (
        <div
          className={`text-green-600 bg-green-100 ${PREFIX_WRAPPER_CLASS_NAME}`}
        >
          <R4KIcon className="w-4" />
        </div>
      );
    }

    if (download.type === 'video-1080p') {
      prefixElement = (
        <div
          className={`text-green-600 bg-green-100 ${PREFIX_WRAPPER_CLASS_NAME}`}
        >
          <R1080PIcon className="w-5" />
        </div>
      );
    }

    if (download.type === 'thumbnail') {
      prefixElement = (
        <div
          className={`text-pink-600 bg-pink-100 ${PREFIX_WRAPPER_CLASS_NAME}`}
        >
          <ImageIcon className="w-4" />
        </div>
      );
    }

    if (download.type === 'subtitles') {
      prefixElement = (
        <div
          className={`text-blue-600 bg-blue-100 ${PREFIX_WRAPPER_CLASS_NAME}`}
        >
          <SubtitlesIcon className="w-4" />
        </div>
      );
    }

    if (download.type === 'info') {
      prefixElement = (
        <div
          className={`text-gray-600 bg-gray-100 ${PREFIX_WRAPPER_CLASS_NAME}`}
        >
          <InfoIcon className="w-4" />
        </div>
      );
    }

    prefixElement =
      prefixElement ||
      (iconClassName ? (
        <div
          className={`text-gray-600 bg-gray-100 ${PREFIX_WRAPPER_CLASS_NAME}`}
        >
          <i className={`${iconClassName} not-italic`}></i>
        </div>
      ) : undefined);

    return (
      <a href={download.url} target="_blank" className={`${className}`}>
        <div
          className={`flex border-2 rounded-md p-3 hover:border-blue-300 cursor-pointer h-full`}
        >
          <div className="flex flex-row items-center break-all">
            {prefixElement}
            {basename}
          </div>
        </div>
      </a>
    );
  },
);

function getBasenameFromURL(url: string) {
  let slashIndex = url.lastIndexOf('/');
  if (slashIndex < 0) {
    return url;
  }

  let basename = url.slice(slashIndex + 1);
  return basename;
}
