import {observer} from 'mobx-react-lite';
import React from 'react';

export interface LighteningIconProps {
  className?: string;
}

export const LighteningIcon: React.FC<LighteningIconProps> = observer(
  (props: LighteningIconProps) => {
    let {className} = props;

    return (
      <svg
        className={`${className}`}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M13 10V3L4 14h7v7l9-11h-7z"
        />
      </svg>
    );
  },
);
