import {observer} from 'mobx-react-lite';
import React from 'react';

export interface R1080PIconProps {
  className?: string;
}

export const R1080PIcon: React.FC<R1080PIconProps> = observer(
  (props: R1080PIconProps) => {
    let {className} = props;

    return (
      <svg
        className={`${className}`}
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 1024 1024"
      >
        <path
          d="M793.944 230.056H230.056c-37.522 0-68.056 30.529-68.056 68.056V725.89c0 37.526 30.534 68.056 68.056 68.056h563.889c37.522 0 68.056-30.529 68.056-68.056V298.111c-0.001-37.526-30.535-68.055-68.057-68.055z m9.723 495.833c0 5.269-4.453 9.722-9.722 9.722H230.056c-5.269 0-9.722-4.453-9.722-9.722V298.111c0-5.269 4.453-9.722 9.722-9.722h563.889c5.269 0 9.722 4.453 9.722 9.722v427.778z"
          p-id="2210"
        ></path>
        <path
          d="M429.361 482.833h-87.5V375.889h-58.333v272.222h58.333V541.167h87.5v106.944h58.333V375.889h-58.333zM672.417 375.889H526.584v272.222h145.833c37.526 0 68.056-30.529 68.056-68.056V443.944c-0.001-37.526-30.53-68.055-68.056-68.055z m9.722 204.167c0 5.269-4.453 9.722-9.722 9.722h-87.5V434.222h87.5c5.269 0 9.722 4.453 9.722 9.722v136.112z"
          p-id="2211"
        ></path>
      </svg>
    );
  },
);
